var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      attrs: {
        visible: _vm.visible,
        closable: true,
        size: "normal",
        title: "完善企业信息",
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
    },
    [
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.tenantForm,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c(
            "a-form-model-item",
            {
              staticClass: "tenant-name",
              attrs: { label: "企业名称", required: "" },
            },
            [
              _c("a-input", {
                attrs: { disabled: "", placeholder: "请输入企业名称" },
                model: {
                  value: _vm.tenantForm.tenantName,
                  callback: function ($$v) {
                    _vm.$set(_vm.tenantForm, "tenantName", $$v)
                  },
                  expression: "tenantForm.tenantName",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "企业规模", prop: "scale" } },
            [
              _c(
                "a-select",
                {
                  staticClass: "selectPlace",
                  attrs: { "allow-clear": true },
                  model: {
                    value: _vm.tenantForm.scale,
                    callback: function ($$v) {
                      _vm.$set(_vm.tenantForm, "scale", $$v)
                    },
                    expression: "tenantForm.scale",
                  },
                },
                [
                  !_vm.tenantForm.scale
                    ? _c("template", { slot: "placeholder" }, [
                        _vm._v(" 请选择 "),
                      ])
                    : _vm._e(),
                  _vm._l(_vm.scaleList, function (item) {
                    return _c(
                      "a-select-option",
                      { key: item.codeKey, attrs: { value: item.codeKey } },
                      [_vm._v(" " + _vm._s(item.content) + " ")]
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "所属行业", prop: "business" } },
            [
              _c(
                "a-select",
                {
                  staticClass: "selectPlace",
                  attrs: { "allow-clear": true },
                  model: {
                    value: _vm.tenantForm.business,
                    callback: function ($$v) {
                      _vm.$set(_vm.tenantForm, "business", $$v)
                    },
                    expression: "tenantForm.business",
                  },
                },
                [
                  !_vm.tenantForm.business
                    ? _c("template", { slot: "placeholder" }, [
                        _vm._v(" 请选择 "),
                      ])
                    : _vm._e(),
                  _vm._l(_vm.treeData, function (item) {
                    return _c(
                      "a-select-opt-group",
                      {
                        key: item.businessTypeId,
                        attrs: { label: item.businessTypeName },
                      },
                      _vm._l(item.businessList, function (it) {
                        return _c(
                          "a-select-option",
                          {
                            key: it.businessId,
                            attrs: { value: it.businessId },
                          },
                          [_vm._v(" " + _vm._s(it.businessName) + " ")]
                        )
                      }),
                      1
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "所在地区", prop: "areaCode" } },
            [
              _c("CbSelectArea", {
                ref: "selectArea",
                attrs: {
                  "province-width": 100,
                  "city-width": 100,
                  "area-width": 100,
                  "auto-fill-in-area-value": false,
                },
                on: { getStrandValue: _vm.onChange },
                model: {
                  value: _vm.tenantForm.areaCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.tenantForm, "areaCode", $$v)
                  },
                  expression: "tenantForm.areaCode",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }